<script setup lang="ts">
import { useImage } from "@vueuse/core";

const props = defineProps<{
  src: string;
  alt: string;
  aspectRatio?: number;
}>();

const { isLoading, isReady, error } = useImage({ src: props.src, alt: props.alt });
</script>

<template>
  <div
    v-if="isLoading || !isReady"
    :class="[{ 'animate-pulse': !error }]"
    :style="`aspect-ratio: ${aspectRatio}`"
    class="bg-grey-70 rounded-md overflow-hidden max-h-[400px]"
  >
    <p v-if="!isReady" class="pt-f4 text-center small-text">{{ alt }}</p>
  </div>
  <img v-else :src="src" :alt="alt" class="max-h-[400px]" />
</template>

<style scoped></style>
