import axios, { AxiosInstance } from "axios";
import { userToken } from "~/utils/localStorage";

export class Api {
  constructor(path?: string) {
    const apiUrl = import.meta.env.VITE_API_URL;
    this.api = axios.create({
      baseURL: `${apiUrl}/${path}`,
    });

    this.api.interceptors.request.use((config) => {
      const token = userToken.get();
      if (token) config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  }

  protected api: AxiosInstance;
}

export const makeApi = (baseURL: string, bearerToken?: string) => {
  const api = axios.create({ baseURL });
  api.interceptors.request.use((config) => {
    const token = bearerToken || userToken.get() || undefined;
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
  return api;
};
