<script setup lang="ts">
import { scaledNftImg, ThumbSize } from "~/composables/useThumbs";
import { ref } from "vue";
import { CardType, NftEquipment, NftSkill } from "fungi-types";

const props = withDefaults(
  defineProps<{
    nft: NftSkill | NftEquipment;
    background?: boolean;
  }>(),
  {
    background: true,
  }
);

const emits = defineEmits<{
  (event: "skill-click", nft: NftSkill): void;
  (event: "equipment-click", nft: NftEquipment): void;
}>();

const nftImage = ref();

function handleCardClick() {
  if (props.nft.card_type === CardType.NftEquipment) {
    emits("equipment-click", props.nft as NftEquipment);
  } else {
    emits("skill-click", props.nft as NftSkill);
  }
}
</script>

<template>
  <div
    :class="{ 'card-container': background }"
    class="flex flex-col gap-3 rounded-md"
    @click.stop="handleCardClick"
  >
    <div class="flex flex-1 flex-col p-4 gap-3 overflow-hidden relative">
      <div class="px-3 flex relative justify-center">
        <div class="relative w-fit">
          <image-skeleton
            :class="
              !nft.card_type || nft.card_type === CardType.NftSkills
                ? 'rounded-t-md rounded-b-[100px]'
                : 'rounded-md '
            "
            ref="nftImage"
            :src="scaledNftImg(nft.image, ThumbSize.L)"
            :alt="nft?.slug || nft.title"
            :aspect-ratio="0.63"
          />
          <!-- <img
            v-if="nftImage && !nftImage.isLoading && nft.scarcity"
            class="w-8 absolute left-0 top-1/2 -translate-y-1/2 -translate-x-1/2"
            :src="`/images/${nft.scarcity}.png`"
            :alt="`${nft.scarcity}`"
          /> -->
        </div>
      </div>
      <div class="footer-data">
        <slot name="footer-data" />
      </div>
      <slot name="cta" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.card-container {
  position: relative;
  border-radius: 10px;
  background: linear-gradient(205deg, rgba(249, 250, 251, 0.1) 0%, rgba(249, 250, 251, 0) 50.08%),
    linear-gradient(25deg, rgba(0, 12, 20, 0.1) 0%, rgba(0, 12, 20, 0) 49.92%),
    rgba(0, 27, 46, 0.15);
  box-shadow:
    0 0 26px 0 rgba(0, 12, 20, 0.1),
    inset 0 0 26px 0 rgba(0, 12, 20, 0.1);
  backdrop-filter: blur(3px);
}

.card-container::before {
  content: "";
  position: absolute;
  inset: 0;
  padding: 1px;
  background: linear-gradient(0deg, #939ba900, #939ba999);
  border-radius: 10px;
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  z-index: -1;
}

.card-container:hover {
  background: linear-gradient(205deg, rgba(249, 250, 251, 0.1) 0%, rgba(249, 250, 251, 0.05) 50.08%),
    linear-gradient(25deg, rgba(0, 12, 20, 0.2) 0%, rgba(0, 12, 20, 0) 49.92%),
    rgba(0, 27, 46, 0.35);
  &::before {
    content: "";
    background: linear-gradient(0deg, #939ba999, #939ba999);
  }
}
</style>
