"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mercureTopicChannel = exports.MercureEventType = void 0;
var MercureEventType;
(function (MercureEventType) {
    MercureEventType["MATIC_PRICE"] = "update-price-matic";
    MercureEventType["AUCTION_UPDATE"] = "update-auction";
    MercureEventType["AUCTION_NEW"] = "new-auction-listing";
    MercureEventType["AUCTION_END"] = "end-auction";
    MercureEventType["UPDATE_BALANCE"] = "update-balance";
    MercureEventType["CANT_BID_WITH_STRIPE"] = "cant-bid-with-stripe";
    MercureEventType["NEW_POL_RATES"] = "pol-rates";
})(MercureEventType || (exports.MercureEventType = MercureEventType = {}));
var mercureTopicChannel;
(function (mercureTopicChannel) {
    mercureTopicChannel["updateAuction"] = "update-auction";
    mercureTopicChannel["updatePriceMatic"] = "update-price-matic";
    mercureTopicChannel["newAuction"] = "new-auction";
    mercureTopicChannel["endAuction"] = "end-auction";
    mercureTopicChannel["updateBalance"] = "update-balance";
    mercureTopicChannel["cantBidWithStripe"] = "cant-bid-with-stripe";
    mercureTopicChannel["polRates"] = "pol-rates";
})(mercureTopicChannel || (exports.mercureTopicChannel = mercureTopicChannel = {}));
