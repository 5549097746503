import { WALLET_ADAPTERS, ADAPTER_EVENTS, WEB3AUTH_NETWORK_TYPE } from "@web3auth/base";
import { OpenloginUserInfo } from "@web3auth/openlogin-adapter";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { ethers } from "ethers";
import mitt from "mitt";
import type { JsonRpcSigner } from "@ethersproject/providers";
import { WalletConnectionType } from "@/application/enums/WalletConnectionType.enum";
import type { Web3Connection } from "@/application/types/Web3Connection";
import { toHex } from "@/composables/web3Utils";
import { chainConfig, clientId, web3Network } from "@/application/config";
import { Web3AuthState } from "@/application/enums/web3AuthState.enum";
import { getED25519Key } from "@toruslabs/openlogin-ed25519";
import { getPublicCompressed } from "@toruslabs/eccrypto";
import { AuthAdapter } from "@web3auth/auth-adapter";

export class Wallet {
  public web3auth: Web3AuthNoModal;
  public connection: Web3Connection;
  public web3authClientStatus: Web3AuthState;
  public currentAccount: string;
  public autoSwitchNetworkOnconnect = true;
  public userInfo: Partial<OpenloginUserInfo>;
  public emitter = mitt();

  constructor() {
    this.currentAccount = "";
    this.userInfo = {} as Partial<OpenloginUserInfo>;

    const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } });
    this.web3auth = new Web3AuthNoModal({
      clientId,
      web3AuthNetwork: web3Network as WEB3AUTH_NETWORK_TYPE,
      privateKeyProvider,
      sessionTime: 86400 * 3,
    });

    const authAdapter = new AuthAdapter({
      adapterSettings: {
        network: web3Network as "mainnet" | "testnet",
        uxMode: "redirect",
      },
    });

    this.web3auth.configureAdapter(authAdapter);

    this.connection = {} as Web3Connection;
    this.connection.type = WalletConnectionType.NONE;
    this.web3authClientStatus = Web3AuthState.IDLE;
  }
  public async setWeb3Connection() {
    this.userInfo = await this.web3auth.getUserInfo();
    const provider = new ethers.providers.Web3Provider(this.web3auth.provider as any);
    const accounts = await provider.listAccounts();
    const network = await provider.getNetwork();
    this.connection = {
      ...this.connection,
      provider,
      accounts,
      network,
      chainId: toHex(network.chainId),
    } as Web3Connection;

    this.currentAccount = this.connection.accounts[0];
    return this.currentAccount;
  }
  async logout(): Promise<void> {
    if (this.web3auth.status === ADAPTER_EVENTS.CONNECTED) {
      await this.web3auth.logout();
    }
  }
  public async connect(email: string): Promise<any> {
    // IF NOT CONNECTED -> trigger web3auth signIn
    if (this.web3auth.status === ADAPTER_EVENTS.CONNECTED) await this.web3auth.logout();
    if (this.web3auth.status === ADAPTER_EVENTS.NOT_READY) await this.web3auth.init();

    await this.web3auth.connectTo(WALLET_ADAPTERS.AUTH, {
      loginProvider: "email_passwordless",
      mfaLevel: "none",
      redirectUrl: `${document.location.origin}`,
      extraLoginOptions: {
        login_hint: email, // email to send the OTP to
        flow_type: "code",
      },
    });
  }

  public async disconnect(): Promise<any> {
    await this.web3auth.logout();
    this.connection = {} as Web3Connection;
    this.connection.type = WalletConnectionType.NONE;
    this.currentAccount = "";

    return Promise.resolve();
  }

  public async signMessage(originalMessage: string): Promise<string> {
    const signedMessage = await this.signer.signMessage(originalMessage);
    return Promise.resolve(signedMessage);
  }

  public async getBalance(): Promise<number> {
    if (!this.provider || !this.accounts) return Promise.reject(Error("No wallet connected"));
    const balance = await this.provider.getBalance(this.accounts[0]);
    const balanceInEth = ethers.utils.formatEther(balance);
    return Promise.resolve(parseFloat(balanceInEth));
  }

  /**
   * Initialize connection (Wallet connect)
   * @returns
   */
  public async initWallet(): Promise<any> {
    await this.web3auth.init();
    if (this.web3auth.connected) await this.setWeb3Connection();
  }

  public get provider(): any {
    return this.connection.provider;
  }

  public get signer(): JsonRpcSigner {
    return this.connection.provider.getSigner() as JsonRpcSigner;
  }

  public get chainId(): string {
    return this.connection.chainId;
  }

  public get accounts(): string[] {
    return this.connection.accounts;
  }

  public get isValidNetwork(): boolean {
    return this.chainId === chainConfig.chainId;
  }

  public async sendTransaction(to: string, amount: string, currency: string = "MATIC") {
    if (!this.web3auth.connected || !this.provider) throw new Error("Wallet is not connected");

    const transaction = {
      to: to,
      value: ethers.utils.parseEther(amount),
    };

    const txResponse = await this.signer.sendTransaction(transaction);
    await txResponse.wait();

    return txResponse.hash;
  }

  public async getPrivateKey() {
    try {
      const _privateKey = await this.web3auth.provider?.request({ method: "eth_private_key" });
      return _privateKey;
    } catch (error) {
      console.error(error);
    }
  }

  public async getEd25519PublicKey(): Promise<string> {
    try {
      const privateKey = (await this.web3auth.provider?.request({
        method: "solanaPrivateKey",
      })) as string;
      const ed25519Key = getED25519Key(Buffer.from(privateKey.padStart(64, "0"), "hex"));
      return ed25519Key.pk.toString("hex");
    } catch (error) {
      console.error("Failed to get ED25519 public key:", error);
      throw new Error("Failed to retrieve ED25519 public key");
    }
  }

  public async getSecp256k1PublicKey(): Promise<string> {
    try {
      const privateKey = (await this.web3auth.provider?.request({
        method: "eth_private_key",
      })) as string;
      const publicKey = getPublicCompressed(
        Buffer.from(privateKey.padStart(64, "0"), "hex")
      ).toString("hex");
      return publicKey;
    } catch (error) {
      console.error("Failed to get SECP256k1 public key:", error);
      throw new Error("Failed to retrieve SECP256k1 public key");
    }
  }
}
