import { ref, watch } from "vue";
import { CourtType, MarketType, NftSkill, Skill } from "fungi-types";
import { asyncComputed } from "@vueuse/core";
import { makeApi } from "~/api";
import {
  mapOwnersHistoryDtoToOwnersHistory,
  OwnersHistory,
  OwnersHistoryDto,
} from "~/components/card-details/types/OwnersHistory";
import { BigNumber } from "ethers";
import {
  mapPriceHistoryDtoToPriceHistory,
  PriceHistory,
  PriceHistoryDto,
} from "~/components/card-details/types/PriceHistory";
import {
  mapPlayerScoresDtoToPlayerScores,
  PlayerScores,
  PlayerScoresDto,
} from "~/components/card-details/types/PlayerScores";
import { PriceTimeframe, ScoreTimeframe } from "~/components/card-details/types/StatsTimeframes";
import { Duration, sub } from "date-fns";
import { MarketplaceApi } from "~/api/marketplace";
import { IAuctionItem, IMarketplaceFilter } from "~/common/interfaces/IMarketplace";
import { defineStore } from "pinia";
import useConvert from "~/composables/useConvert";

const baseUrl = import.meta.env.VITE_API_URL;
const api = makeApi(baseUrl);
const nftSkillApi = makeApi(`${baseUrl}/services/nftSkill`);
const skillsApi = makeApi(`${baseUrl}/skills`);

const defaultSkill: Skill = Skill.RETURN;
const defaultScoresDataPoints: ScoreTimeframe = 10;
const defaultPriceTimeframe: PriceTimeframe = { months: 3 };

const defaultPriceHistory: PriceHistory = {
  params: {},
  data: [],
  stats: {
    dataPoints: 0,
    average: BigNumber.from(0),
    min: BigNumber.from(0),
    max: BigNumber.from(0),
  },
};

const defaultPlayerScores: PlayerScores = {
  data: [],
  stats: {
    dataPoints: 0,
    participationRate: 0,
    averages: {
      [Skill.MENTAL]: 0,
      [Skill.POWER]: 0,
      [Skill.RETURN]: 0,
      [Skill.SERVICE]: 0,
    },
  },
};

const primaryMarketplaceApi = new MarketplaceApi(MarketType.PRIMARY);
const secondaryMarketplaceApi = new MarketplaceApi(MarketType.SECONDARY);

export type CurrentlyAvailableCardAuction = IAuctionItem & {
  placeholder?: boolean;
  owner: IAuctionItem["card"]["owner"];
  level: NftSkill["skillLevel"];
  wear?: number;
  maticPrice?: number;
};

const { weiToMatic } = useConvert;

export const useCardDetails = defineStore("card-details", () => {
  const card = ref<NftSkill | null>(null);
  const placeholderCard = ref<NftSkill | null>(null);
  const tennisPlayerId = ref<number | null>(null);
  const skill = ref<Skill | null>(null);

  const scoresDataPoints = ref<ScoreTimeframe>(defaultScoresDataPoints);
  const priceTimeframe = ref<PriceTimeframe>(defaultPriceTimeframe as PriceTimeframe);

  const loadingOwnersHistory = ref(false);
  const loadingPriceHistory = ref(false);
  const loadingScores = ref(false);
  const loadingSales = ref(false);

  const showModal = ref(false);

  const ownersHistory = asyncComputed(
    async () => {
      if (!card.value) return [] as OwnersHistory;

      try {
        const res = await api.get<OwnersHistoryDto>(`/skills/${card.value.tokenId}/owners`);
        return mapOwnersHistoryDtoToOwnersHistory(res.data);
      } catch (err) {
        console.error("Something went wrong while fetching card owners history", {
          card: card.value,
          error: err,
        });

        return [] as OwnersHistory;
      }
    },
    [] as OwnersHistory,
    loadingOwnersHistory
  );

  const priceHistory = asyncComputed(
    async () => {
      if (!card.value || !skill.value) return defaultPriceHistory;

      const params = {
        first_name: card.value.firstName,
        last_name: card.value.lastName,
        scarcity: card.value.scarcity,
        skill: skill.value,
        court_type: card.value.courtType,
        season: card.value.season,
        time_end: new Date().toISOString(),
        time_start:
          priceTimeframe.value !== undefined
            ? sub(new Date(), priceTimeframe.value as Duration).toISOString()
            : undefined,
      };

      try {
        const res = await api.get<PriceHistoryDto>(`/skills/price-history`, {
          params,
        });

        return mapPriceHistoryDtoToPriceHistory(res.data);
      } catch (err) {
        console.error("Something went wrong while fetching price history for card", {
          params,
          error: err,
        });

        return defaultPriceHistory;
      }
    },
    defaultPriceHistory,
    loadingPriceHistory
  );

  async function fetchScores(params: Record<string, unknown>) {
    try {
      const res = await api.get<PlayerScoresDto>("/services/tennis-player-scores", {
        params,
      });

      return mapPlayerScoresDtoToPlayerScores(res.data);
    } catch (err) {
      console.error("Something went wrong while fetching scores", { params, error: err });

      return defaultPlayerScores;
    }
  }

  const scores = asyncComputed(
    async () => {
      if (!card.value || !skill.value) return defaultPlayerScores;

      const params = {
        first_name: card.value?.firstName,
        last_name: card.value?.lastName,
        data_points: scoresDataPoints.value,
      };

      return fetchScores(params);
    },
    defaultPlayerScores,
    loadingScores
  );

  const l10Scores = asyncComputed(async () => {
    if (!card.value || !skill.value) return defaultPlayerScores.stats.averages;

    const params = {
      first_name: card.value?.firstName,
      last_name: card.value?.lastName,
      data_points: 10,
      stats_only: true,
    };

    const scores = await fetchScores(params);

    return {
      [Skill.MENTAL]: Math.round(scores.stats.averages[Skill.MENTAL]),
      [Skill.POWER]: Math.round(scores.stats.averages[Skill.POWER]),
      [Skill.RETURN]: Math.round(scores.stats.averages[Skill.RETURN]),
      [Skill.SERVICE]: Math.round(scores.stats.averages[Skill.SERVICE]),
    } as Record<Skill, number>;
  }, defaultPlayerScores.stats.averages);

  const cardSales = asyncComputed<CurrentlyAvailableCardAuction[]>(
    async () => {
      if (!card.value || !skill.value) return [];

      const filters: IMarketplaceFilter = {
        // Only using lastName as specifying both first and last name results in no auctions being returned
        player_name: card.value.lastName,
        scarcity: [card.value.scarcity],
        skill: [skill.value],
      };

      try {
        const sales = await Promise.all([
          primaryMarketplaceApi.getCards(filters),
          secondaryMarketplaceApi.getCards(filters),
        ]);

        const onSale = sales
          .map((result) => result.data)
          .flat()
          .map<CurrentlyAvailableCardAuction>((sale) => ({
            ...sale,
            owner:
              sale.marketType === "primary"
                ? "Fungiball"
                : ((sale.card.ownedBy?.user as { username: string } | undefined)?.username ??
                  "N/A"),
            level: (sale.card as NftSkill).skillLevel,
            // TODO: map wear
            wear: undefined,
            maticPrice: weiToMatic(BigNumber.from(sale.lastBidAmount ?? sale.startedAmount!)),
          }));

        if (onSale.length !== 0) {
          placeholderCard.value = null;
          return onSale;
        }

        try {
          placeholderCard.value = null;
          const res = await skillsApi.get<{ data: NftSkill[] }>("/", {
            params: {
              tennisPlayerId: tennisPlayerId.value,
              skill: skill.value,
              scarcity: card.value?.scarcity,
              nftOnly: true,
            },
          });

          if (res.data.data.length === 0) {
            console.info(
              `No token found for ${skill.value} ${card.value?.scarcity} and tennis player ${tennisPlayerId.value}`
            );
            return [];
          }

          placeholderCard.value = res.data.data[0];

          return [];
        } catch (err) {
          console.error("Something went wrong while fetching token", { error: err });
          return [];
        }
      } catch (err) {
        console.error("Something went wrong while fetching card sales", { error: err });

        return [];
      }
    },
    [],
    loadingSales
  );

  const clearCard = () => {
    card.value = null;
    tennisPlayerId.value = null;
    cardSales.value = [];
    skill.value = null;
    ownersHistory.value = [];
    priceHistory.value = defaultPriceHistory;
    scores.value = defaultPlayerScores;
    l10Scores.value = defaultPlayerScores.stats.averages;
  };

  const loadCard = async (tokenId: NftSkill["tokenId"], tempValue?: NftSkill) => {
    clearCard();
    if (tempValue) {
      card.value = tempValue;
    }

    placeholderCard.value = null;

    const res = await nftSkillApi.get<
      Omit<NftSkill, "courtType" | "countryIso"> & {
        court: CourtType;
        countryISO: string;
        tennisPlayer: {
          id: number;
        };
      }
    >(`/${tokenId}`);

    card.value = {
      ...res.data,
      courtType: res.data.court,
      countryIso: res.data.countryISO,
    };

    tennisPlayerId.value = res.data.tennisPlayer.id;
  };

  watch(card, (newCard) => {
    if (newCard === null) {
      skill.value = defaultSkill;
      return;
    }

    if (newCard.skill !== skill.value) {
      skill.value = newCard.skill;
    }
  });

  return {
    card,
    placeholderCard,
    skill,
    ownersHistory,
    priceHistory,
    scores,
    l10Scores,
    cardSales,
    scoresDataPoints,
    priceTimeframe,
    loadingOwnersHistory,
    loadingPriceHistory,
    loadingScores,
    loadingSales,
    showModal,
    loadCard,
  };
});
