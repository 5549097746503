<script setup lang="ts">
import { NftSkill, Skill } from "fungi-types";
import { useI18n } from "vue-i18n";
import L5Chart from "~/components/marketplace/L5Chart.vue";

const { t } = useI18n();

const props = withDefaults(
  defineProps<{
    l10: number;
    tokenId: number;
    skill: Skill;
    l5: NftSkill["l5"];
  }>(),
  {
    l10: undefined,
    l5: undefined,
  }
);
</script>

<template>
  <div class="w-full flex items-stretch justify-between text-black">
    <Tooltip :text="t('card.l10.tooltipLegend')">
      <div
        :class="{
          'w-full rounded-lg': true,
          'bg-[#CCD0D6]': l10 === null,
          'bg-[#40AA48]': l10 !== null && l10 >= 71,
          'bg-[#32aecd]': l10 !== null && l10 >= 51 && l10 <= 70,
          'bg-[#F2BD0D]': l10 !== null && l10 >= 31 && l10 <= 50,
          'bg-[#E38112]': l10 !== null && l10 >= 11 && l10 <= 30,
          'bg-[#BD270E]': l10 !== null && l10 < 11,
        }"
        class="rounded-md px-2 small-text lg:text-sm score-container grid place-content-center"
      >
        <p class="font-sans font-medium">{{ l10 }}</p>
      </div>
    </Tooltip>

    <L5Chart :l5="l5" />
  </div>
</template>

<style scoped lang="scss">
.return-bg {
  @apply bg-skill-return;
}
.service-bg {
  @apply bg-skill-service;
}
.power-bg {
  @apply bg-skill-power;
}
.mental-bg {
  @apply bg-skill-mental;
}
</style>
