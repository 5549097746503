<script setup lang="ts">
import { env, build, isProd } from "~/application/config";
import Popper from "vue3-popper";
import { ref, computed } from "vue";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import usePOLPrice from "~/stores/POLprice";

const envVars = ref({});

const storeUser = useStoreUserV2();
const polPrice = usePOLPrice();
function dumpVar() {
  envVars.value = import.meta.env;
}

const w3a = computed<any>(() => {
  return storeUser.wallet.web3auth.coreOptions;
});
</script>

<template>
  <Popper hover arrow @open:popper="dumpVar">
    <span class="text-sm font-mono">{{ env }} | {{ build }}</span>
    <template #content>
      <div class="whitespace-pre-wrap flex flex-col items-center gap-1 text-primaryLight">
        <span v-if="isProd">fungiball</span>
        <ul>
          <li v-for="(value, key) in envVars" :key="key">
            <span class="text-teal-500">{{ key }}</span
            >: <span>{{ value }}</span>
          </li>
          <hr />
          <li>
            <span class="text-yellow-500">Auth. Matic: </span>{{ storeUser.allowedWMaticAmount }}
          </li>
          <li><span class="text-yellow-500">Expenses: </span>{{ storeUser.ongoingExpenses }}</li>
          <li>
            <span class="text-yellow-500">POL rates (1 POL =): </span>
            <span> {{ polPrice.rates.usd }} USD | {{ polPrice.rates.eur }} EUR </span>
          </li>
          <hr />
          <li>
            <span class="text-purple-500">web3auth: </span>{{ storeUser.wallet.web3auth.status }}
          </li>

          <li v-for="(value, key) in w3a" :key="key">
            <span class="text-purple-500">{{ key }}</span
            >: <span>{{ JSON.stringify(value) }}</span>
          </li>
        </ul>
      </div>
    </template>
  </Popper>
</template>
