import { WALLET_ADAPTERS, ADAPTER_EVENTS } from "@web3auth/base";
import { BigNumber, ethers } from "ethers";
import { defineStore } from "pinia";
import { allowWMaticMinAmount, autoUpdateFeq } from "@/application/config";
import { ProviderType } from "@/application/enums/ProviderType.enum";
import { displayError } from "@/utils/errors";
import { useStoreContract } from "./storeContract";
import { toEther } from "~/composables/web3Utils";
import useConvert from "~/composables/useConvert";
import { AlchemyService } from "~/services/AlchemyService";
import { Wallet } from "@/application/entity/wallet";
import { userToken } from "~/utils/localStorage";
import { shallowRef } from "vue";
import { authApi, usersApi } from "~/api/auth";
import { EPreferredLanguage, PreferredLanguage, supportedLanguages, Currency } from "fungi-types";
import translations from "~/modules/translations";
import useOnboarding from "~/components/modals/onboarding/use-onboarding";
import { onboardingApi } from "~/api/onboarding";
import OnboardingEventType from "~/application/enums/OnboardingEventType";

export const useStoreUserV2 = defineStore("user-storeV2", {
  state: () => ({
    username: "",
    email: "",
    messageToSign: "",
    messageRegisterSigned: "",
    cuid: "",
    optin: false,
    preferredLanguage: null as PreferredLanguage | null,
    preferredCurrency: Currency.USD as Currency,
    mustRegister: false,

    // Sponsorship
    referralCode: "",
    referrerCode: "",
    referralLevel: 0,

    // Alchemy
    alchemy: new AlchemyService(),

    // Wallet Info
    wallet: shallowRef(new Wallet()),
    isWalletConnected: false,
    walletAddress: "",
    currentMaticBalance: 0,
    currentWMaticBalance: 0,
    refreshTimer: 0,
    lastRefresh: 0,
    messageLoginSigned: "",
    ongoingExpenses: 0,
    allowedWMaticAmount: 0,
  }),
  getters: {
    availableWMaticBalance: (state) => {
      return state.currentWMaticBalance ? state.currentWMaticBalance - state.ongoingExpenses : 0;
    },
    weiMaticBalance: (state) => {
      return ethers.utils.parseEther(state.currentMaticBalance.toString());
    },
  },
  actions: {
    async init() {
      this.wallet.web3auth.on(ADAPTER_EVENTS.CONNECTED, async () => {
        console.info("Connected");
        this.isWalletConnected = true;

        await this.startOnboardingIfNeeded();
      });
      this.wallet.web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
        console.info("Disconnected");
        this.isWalletConnected = false;
        useOnboarding().hideOnboarding();
      });
      this.wallet.web3auth.on(ADAPTER_EVENTS.ERRORED, (error: any) => {
        console.error("Error on web3auth", error);
      });

      await this.initWallet();

      if (this.wallet.web3auth.connected) {
        return await this.login();
      }
      return 200;
    },
    rename(newName: string) {
      this.username = newName;
    },
    async initWallet() {
      await this.wallet.web3auth.init();
      if (this.wallet.web3auth.connected) {
        await this.wallet.setWeb3Connection();
        this.walletAddress = this.wallet.currentAccount;
        await useStoreContract().connectUserProvider(this.wallet.provider);
        this.email = this.wallet.userInfo.email || "";
        this.autoRefreshBalance();
      }
    },

    async getMaticBalance() {
      this.currentMaticBalance = await this.wallet.getBalance();
    },
    async getWMaticBalance() {
      if (useStoreContract().providerType === ProviderType.WEB3) {
        this.currentWMaticBalance = await useStoreContract().getWmtcBalance(
          this.wallet.currentAccount
        );
      }
    },

    async updateUserPolBalance() {
      const userInfo = await authApi.getUserInfo();
      this.getWMaticBalance().then(() => {
        this.ongoingExpenses = toEther(BigNumber.from(userInfo.currentWallet.spendAmount));
      });
    },

    async refreshBalance() {
      if (this.isWalletConnected) {
        await this.getMaticBalance();
        await this.getWMaticBalance();
      }
    },
    async autoRefreshBalance() {
      await this.refreshBalance();

      this.refreshTimer = window.setTimeout(() => {
        this.autoRefreshBalance();
      }, autoUpdateFeq);

      this.lastRefresh = Date.now();
    },
    async setUserInfo() {
      const userInfo = await authApi.getUserInfo();
      this.username = userInfo.username;
      this.referrerCode = userInfo.referralCode;
      this.referralLevel = userInfo.referralLevel;
      this.cuid = userInfo.cuid;
      this.optin = userInfo.optin;
      this.preferredLanguage = userInfo.preferredLanguage;
      this.preferredCurrency = userInfo.preferredCurrency;
      this.ongoingExpenses = toEther(BigNumber.from(userInfo.currentWallet.spendAmount));

      this.updatePreferredLanguage(userInfo.preferredLanguage);
    },
    updatePreferredLanguage(lang: PreferredLanguage, forceApiCall = false) {
      if (lang !== null) {
        this.preferredLanguage = lang;

        if (forceApiCall) {
          usersApi.setUserPreferredLanguage(lang).catch(console.error);
        }
      } else {
        const language =
          (supportedLanguages as EPreferredLanguage[]).find((l) =>
            navigator.language.startsWith(l)
          ) ?? EPreferredLanguage.EN;

        this.preferredLanguage = language;
        usersApi.setUserPreferredLanguage(language).catch(console.error);
      }
    },
    updatePreferredCurrency(currency: Currency | "wmtc", forceApiCall = false) {
      this.preferredCurrency = currency;
      usersApi.setUserPreferredCurrency(currency).catch(console.error);
    },
    async startOnboardingIfNeeded() {
      try {
        const lastOnboardingEvent = await onboardingApi.getLastEvent();
        if (
          lastOnboardingEvent !== OnboardingEventType.STEP_17 &&
          lastOnboardingEvent !== OnboardingEventType.IGNORED
        ) {
          useOnboarding().startOnboarding();
        }
      } catch (err) {
        // Should never happen, here as a safeguard
        console.error("Unable to determine whether or not to start onboarding for user", err);
      }
    },
    async login() {
      try {
        const idToken = this.wallet.userInfo.idToken;
        const appPubKey = await this.wallet.getSecp256k1PublicKey();
        const userTokens = await authApi.login({
          appPublicKey: appPubKey,
          idToken,
        });

        userToken.set(userTokens.jwt);
        await this.setUserInfo();
        await this.startOnboardingIfNeeded();

        return 200;
      } catch (error: any) {
        const status = error.response?.status;
        if (status === 404) {
          this.mustRegister = true;
          return 404;
        } else if (status === 401) return 401;
        else displayError(error);
      }
    },
    async logout() {
      await this.wallet.logout();
      userToken.remove();
      this.username = "";
      this.mustRegister = false;
      this.cuid = "";
    },
    async register({
      optin,
      username,
      referalCode,
    }: {
      optin: boolean;
      username: string;
      referalCode: string;
    }) {
      const { message } = await authApi.getRegisterMessage({
        username,
        optin,
        email: this.email,
        address: this.walletAddress,
      });
      this.messageToSign = message;
      this.messageRegisterSigned = await this.wallet.signMessage(message);
      await authApi.register({
        email: this.email,
        address: this.walletAddress,
        signature: this.messageRegisterSigned,
        username,
        referralCode: referalCode,
        optin,
        idToken: this.wallet.userInfo.idToken,
        preferredLanguage:
          this.preferredLanguage ??
          translations.supportedLocales.find((l) => navigator.language.startsWith(l)) ??
          EPreferredLanguage.EN,
      });
      this.removeRefCode();
      if (this.mustRegister === true) this.mustRegister = false;
    },
    setRefCode(referralCode: string) {
      localStorage.setItem("referralCode", referralCode);
    },
    getRefCode() {
      return localStorage.getItem("referralCode") || "";
    },
    removeRefCode() {
      return localStorage.removeItem("referralCode");
    },
    async connectWallet(email: string) {
      const { web3auth } = this.wallet;
      if (web3auth.status === ADAPTER_EVENTS.CONNECTED) await this.wallet.web3auth.logout();
      else if (web3auth.status === ADAPTER_EVENTS.NOT_READY) await this.wallet.web3auth.init();
      else await this.wallet.connect(email);
    },
    async allowWmtc(amount: number = 0): Promise<boolean> {
      const min = Math.max(allowWMaticMinAmount, amount);
      const allowAmount = Math.max(100_000, amount);

      // already allowed : return
      if (this.allowedWMaticAmount >= min) {
        return true;
      }

      // update balance
      if (useStoreContract().providerType === ProviderType.WEB3) {
        this.allowedWMaticAmount = await useStoreContract().getAllowedWmtc(
          this.wallet.currentAccount
        );
      } else {
        const matic = await this.alchemy.getWmaticBalance(this.walletAddress);
        this.allowedWMaticAmount = useConvert.weiToMatic(matic ?? 0);
      }

      if (this.allowedWMaticAmount >= min) {
        return true;
      }

      // allow amount
      try {
        const tx = await useStoreContract().allowWmtc(allowAmount);
        const confirm = await tx.wait(1);
        if (confirm.events?.length) {
          this.allowedWMaticAmount = +ethers.utils.formatUnits(confirm.events[0].data, 18);
        }
        return Promise.resolve(this.allowedWMaticAmount >= min);
      } catch (error) {
        console.error(error);
        return Promise.resolve(false);
      }
    },
    async getGasPrice() {
      if (this.wallet.provider) {
        return await this.wallet.provider.getGasPrice();
      } else {
        throw new Error("No provider");
      }
    },
  },
});
