<script setup></script>
<template>
  <div class="footer-data p-2 gap-2 flex flex-col">
    <slot name="header"></slot>
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.footer-data {
  @apply rounded-md bg-grey-40/10 w-full overflow-hidden;
}
</style>
