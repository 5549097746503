<script setup lang="ts">
import { IAuctionItem } from "~/common/interfaces/IMarketplace";
import { computed, onBeforeUnmount, onMounted, PropType, Ref, ref, watch } from "vue";
import { BigNumber } from "ethers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";
import { useStoreApp } from "~/stores/storeApp";
import { useStoreMarketplace } from "~/stores/storeMarketplace";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { useI18n } from "vue-i18n";
import { gsap, Power1 } from "gsap";
import { useRouter } from "vue-router";
import {
  CardType,
  NftSkill,
  AuctionUpdate,
  MercureEventType,
  AuctionStatus,
  MarketType,
} from "fungi-types";
import useOnboarding from "~/components/modals/onboarding/use-onboarding";
import { useCardDetails } from "../card-details/use-card-details";
import usePOLPrice from "~/stores/POLprice";
import CardDataFooterScore from "../card/cardDataFooterScore.vue";
import CardDataFooterSkeleton from "../card/cardDataFooterSkeleton.vue";
import CardDataFooterPrice from "../card/cardDataFooterPrice.vue";

import NftSkillCard from "./NftSkill.vue";

dayjs.extend(utc);
dayjs.extend(duration);

const props = defineProps({
  item: {
    type: Object as PropType<IAuctionItem & { onboarding?: boolean }>,
    required: true,
  },
  showCardDetails: {
    type: Boolean,
    required: false,
    default: true,
  },
  isInteractive: {
    type: Boolean,
    required: false,
    default: true,
  },
  isOnboarding: {
    type: Boolean,
    required: false,
    default: false,
  },
  currentlyAvailable: {
    type: Number,
    required: false,
    default: -1,
  },
});

const emits = defineEmits<{
  (event: "buy", data: { item: IAuctionItem; loading: Ref }): void;
  (event: "card-click", data: { card: IAuctionItem["card"] }): void;
}>();

const { t } = useI18n();
const router = useRouter();
const polPrice = usePOLPrice();
const storeUser = useStoreUserV2();
const timeOver = ref(false);
const loading = ref(false);
const nftImage = ref();
const auctionStatus = ref<AuctionStatus>(props.item.status);

const _weiAmount = computed(() => {
  return BigNumber.from(props.item.lastBidAmount ?? props.item.startedAmount);
});

const handleTimeoutEvent = () => {
  timeOver.value = true;
};

function updateAuction(data: AuctionUpdate) {
  if (data.auction.cuid === props.item.cuid) {
    const end = new Date(data.auction.endedAt);

    // check if the auction has ended
    timeOver.value = end.getTime() <= new Date().getTime();
    animName();

    auctionStatus.value = data.auction.status;
  }
}

/**
 * Perform the red animation
 */
function animName() {
  gsap.fromTo(
    `#bidname${props.item.cuid}`,
    { scale: 1.2 },
    {
      duration: 0.12,
      scale: 1,
      ease: Power1.easeInOut,
    }
  );
}

function bidOnCard() {
  if (props.isOnboarding) {
    useOnboarding().goToNextStep();
    return;
  }

  useStoreMarketplace().selectedItem = props.item;
  useStoreApp().setBidModalState(true);
}

const isOwner = computed(() => {
  if (!storeUser.walletAddress) return false;
  return storeUser.walletAddress === props.item?.card.owner;
});

function buyItem(type: "modal" | "direct") {
  if (!storeUser.isWalletConnected) {
    router.push({ name: "Login" });
  } else if (type === "modal") {
    useStoreMarketplace().selectedItem = props.item;
    useStoreApp().showBuyModal = true;
  } else {
    emits("buy", { item: props.item, loading: loading });
  }
}

onMounted(() => {
  useStoreMarketplace().emitter.on(MercureEventType.AUCTION_UPDATE, updateAuction);
});

onBeforeUnmount(() => {
  useStoreMarketplace().emitter.off(MercureEventType.AUCTION_UPDATE, updateAuction);
});

watch(
  () => [props.item, auctionStatus],
  () => {
    if (props.item?.status === AuctionStatus.ENDED || auctionStatus.value === AuctionStatus.ENDED) {
      loading.value = false;
    }
  }
);

const cardDetails = useCardDetails();
const navigateToCardDetails = (cardToShowTheDetailsFor: NftSkill) => {
  cardDetails.loadCard(cardToShowTheDetailsFor.tokenId, cardToShowTheDetailsFor);
  cardDetails.showModal = true;
};

const handleClickOnCard = () => {
  if (props.showCardDetails && props.item.type !== CardType.NftEquipment) {
    navigateToCardDetails(props.item.card);
  } else {
    emits("card-click", { card: props.item.card });
  }
};
</script>

<template>
  <NftSkillCard :nft="item.card" @click="handleClickOnCard">
    <template #footer-data>
      <CardDataFooterSkeleton>
        <template #header>
          <CardDataFooterScore
            v-if="item.type !== CardType.NftEquipment"
            :l10="item.card.score"
            :l5="item.card.l5"
            :skill="item.card.skill"
            :token-id="1"
          />
        </template>

        <CardDataFooterPrice
          :weiAmount="_weiAmount"
          :marketType="item.marketType"
          :cuid="item.cuid"
          :endedAt="item.endedAt"
          :status="auctionStatus"
          :lastBidder="item.lastBidder?.username ?? item.card.ownedBy?.user.username"
          @time-out="handleTimeoutEvent"
        />
      </CardDataFooterSkeleton>
    </template>
    <template #cta>
      <div
        class="inset-0 absolute flex items-center justify-center overflow-hidden"
        :class="auctionStatus === AuctionStatus.ENDED ? 'flex winner' : 'hidden'"
      >
        <div
          class="bg-darker-blury w-full items-center justify-center flex winner p-3 text-center font-display border-y border-white pointer-events-none uppercase"
        >
          <span v-if="item.marketType === MarketType.SECONDARY">
            {{ $t("pages.marketplace.trade.sold") }}
          </span>
          <span v-else>
            {{ t("pages.marketplace.NewCards.owned-by", { s: item.lastBidder?.username ?? "" }) }}
          </span>
        </div>
      </div>

      <div v-if="isInteractive" class="font-display w-full flex" @click.stop>
        <div class="w-full" v-if="item.marketType === MarketType.SECONDARY">
          <ButtonPrimary v-if="isOwner" disabled class="btn-primary w-full py-f2">
            {{ $t("pages.marketplace.trade.my-card") }}
          </ButtonPrimary>
          <ButtonPrimary v-else-if="auctionStatus === AuctionStatus.ENDED" disabled class="w-full">
            {{ $t("pages.marketplace.NewCards.ended") }}
          </ButtonPrimary>
          <ButtonPrimary
            v-else
            @click="buyItem('modal')"
            class="w-full capitalize"
            :disabled="loading || auctionStatus === AuctionStatus.PENDING_TO_BE_EXECUTED"
          >
            {{ $t("pages.marketplace.trade.buy") }}
          </ButtonPrimary>
          <div
            v-if="props.currentlyAvailable >= 0"
            class="w-full font-sans mt-2 bg-grey-30 bg-opacity-20 rounded-md py-2 px-4 flex flex-row justify-center items-center gap-2"
          >
            <span>{{ t("cardDetails.availableCards.title") }}</span>
            <div
              class="flex items-center justify-center aspect-square w-5 bg-white text-grey-90 rounded-full"
            >
              <span class="text-sm leading-none">
                {{ props.currentlyAvailable }}
              </span>
            </div>
          </div>
        </div>
        <div class="w-full" v-else>
          <ButtonPrimary
            v-if="auctionStatus === AuctionStatus.PENDING_TO_BE_EXECUTED"
            disabled
            class="w-full capitalize"
          >
            <icon-fgc-loading />
          </ButtonPrimary>
          <ButtonPrimary
            v-else-if="
              timeOver || [AuctionStatus.ENDED || AuctionStatus.CANCELLED].includes(auctionStatus)
            "
            disabled
            class="w-full capitalize"
          >
            {{ $t("pages.marketplace.NewCards.ended") }}
          </ButtonPrimary>
          <ButtonPrimary
            v-else
            :class="{ 'w-full capitalize': true, 'ring-2 animate-pulse': !!item.onboarding }"
            @click="bidOnCard"
            :disabled="timeOver"
          >
            {{ $t("pages.marketplace.NewCards.bid-now") }}
          </ButtonPrimary>
          <div
            v-if="props.currentlyAvailable >= 0"
            class="w-full font-sans mt-2 bg-grey-30 bg-opacity-20 rounded-md py-2 px-4 flex flex-row justify-center items-center gap-2"
          >
            <span>{{ t("cardDetails.availableCards.title") }}</span>
            <div
              class="flex items-center justify-center aspect-square w-5 bg-white text-grey-90 rounded-full"
            >
              <span class="text-sm leading-none">
                {{ props.currentlyAvailable }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </NftSkillCard>
</template>

<style scoped lang="scss">
.winner {
  animation: winner 0.6s cubic-bezier(0.87, 0, 0.13, 1);
}

@keyframes winner {
  from {
    scale: 1.66;
    filter: drop-shadow(0 0 4em #ffffff);
  }
  to {
    scale: 1;
    filter: drop-shadow(0 0 0em #ffffff);
  }
}
</style>
